import React from "react"
import { isIE, browserVersion } from "react-device-detect"
import * as Sentry from "@sentry/browser"
import ErrorPage from "../components/ErrorPage"

if (!isIE || (isIE && browserVersion !== "11.0")) {
    Sentry.init({
        dsn: "https://efddb4afba9e429d8567a107f36e20a1@sentry.io/1482543"
    })
}

class Error extends React.Component {
    static getInitialProps({ res, err }) {
        const statusCode = res ? res.statusCode : err ? err.statusCode : null
        if (err) {
            if (!isIE || (isIE && browserVersion !== "11.0")) {
                Sentry.captureException(err)
                console.error("ERROR PAGE: ", err)
            }
        }
        return { statusCode }
    }

    render() {
        return (
            <ErrorPage
                config={this.props.config}
                errorCode={this.props.statusCode}
                disablePublicHeader
            />
        )
    }
}

export default Error
